@import '~antd/dist/antd.css';

a {
  text-decoration: none;
}
.MuiDateTimePickerToolbar-timeContainer button {
  margin-top: 0px !important;
  margin-bottom: 0px !important ;

}

.marginRightLeft20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.hoverable:hover {
  cursor: pointer;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justifyBetween {
  justify-content: space-between !important;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(196, 196, 196);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(121, 121, 121);
  border-radius: 10px;
}

/* global page container */

.pageContainer {
  width: 98%;
}

.grayText {
  color: #909090 !important;
}

.boldText {
  font-weight: 600 !important;
}

.blackText {
  color: #2c2c2c !important;
}

.fullWidth {
  width: 100% !important;
}

li {
  text-align: left;
  font-size: 13px;
  font-family: Nunito;
  letter-spacing: 0px;
  color: #626262;
  opacity: 1;
}

.nunito {
  font-family: Nunito;
}

.size12 {
  font-size: 12px !important;
}
.size10 {
  font-size: 10px !important;
}

.size16 {
  font-size: 14px !important;
}

.size8 {
  font-size: 16px !important;

}

.rightAlign {
  text-align: right !important;
}

.editIconStyle {
  font-size: 15px;
  /* height: 15px; */
}

.padding {
  padding: 13px 15px;
}

.colorPrimary {
  color: #FD9567;
}

.backgroundLightPrimary {
  background-color: #FFF5F0 !important;
}

.shadow {
  box-shadow: 0px 3px 10px #0000000f !important;
}

.margin {
  margin-top: 10px !important;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: unset !important;
  }
}

.size14 {
  font-size: 12px !important;
}

.lightGrayText {
  color: #a5aeb7 !important;
}

.marginBottom {
  margin-bottom: 40px !important;
}

.transtable .MuiTablePagination-actions {
  display: none !important;
}

.normalText {
  font-weight: 400 !important;
}

.marginTopBottom {
  margin-top: 40px;
  margin-bottom: 40px;
}

hr {
  opacity: 0.6;
}

.roundBtn {
  margin-top: 0px !important;
  height: 24px !important;
  width: 24px !important;
  border-radius: 50% !important;
  padding: 0px !important;
}

.margin0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.grayBackground {
  background: #ededed 0% 0% no-repeat padding-box !important;
}

.tabs .MuiButtonBase-root {
  padding: unset !important;
  margin-right: 25px !important;
}

.marginTop {
  padding-top: 40px;
}

.fontWhite {
  color: #ffffff !important;
}
.gradientText {
    background: linear-gradient(91.31deg, #AC8BE3, #F28D2F);
    background-clip: text;
    -webkit-text-fill-color: transparent;
 
}

/* tspan {
  text-align: left;
  font: normal normal normal 10px/14px Nunito Sans;
  letter-spacing: 0px;
  color: #2c2c2c;
}

*/
.recharts-label tspan {
  font-family: Nunito;
  font-size: 10px;
  fill: #909090;
}

.fullHeight {
  height: 100% !important;
}

.MuiInputBase-root.Mui-disabled {
  cursor: not-allowed !important;
  background-color: #F6F6F6 !important;
  color: #909090 !important;
}

.dateRangePickerWrapper {
  position: absolute !important;
  left: -38vw;
}

.mui-daterange-picker-makeStyles-filled-25 {
  background-color: #FD9567 !important;
}

.mui-daterange-picker-makeStyles-outlined-24 {
  border: 1px solid #FD9567;
}

.mui-daterange-picker-makeStyles-highlighted-25 {
  background-color: #FFF5F0 !important;
}

.width95 {
  width: 95% !important;
}

.link {
  color: #777777 !important
}

.textTruncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.textTruncateOneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.textTruncateTwoLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


.scrollable {
  overflow-y: scroll;
}

.recharts-tooltip-wrapper {
  font-family: 'Montserrat';
}

.errorText {
  font-size: 12px !important;
  margin-left: 10px !important;
  color: #f44336;
  margin-top: -7px !important;
}

.helpList {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin: 0;
  padding: 0;
  margin-left: 40px !important;
  margin-top: 10px;
}

.helpList li {
  margin: 0 0 0.5rem 0;
  counter-increment: my-awesome-counter;
  position: relative;
}

.helpList li::before {
  content: counter(my-awesome-counter);
  color: #ffffff;
  font-size: 0.6rem;
  font-weight: bold;
  position: absolute;
  --size: 18px;
  left: calc(-1 * var(--size) - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  top: 0;
  background: black;
  border-radius: 50%;
  text-align: center;
}

/* 
.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.data-grid-cells {
  font-size: 11px;
}

.link {
  color: #777777
}

/* 
  for zoho ticket widget on the bottom right corner.
*/
#zohohc-asap-web-launcherbox {
  height: 30px;
  width: 30px;
}

#semiBoldText{
  font-weight: 500 !important;
}

.semiBoldText{
  font-weight: 500 !important;
}

.marginBottomTen {
  margin-bottom: 10px !important;
}

.MuiPickersToolbarButton-toolbarBtn{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.note_typography {
  font-family: Nunito;
  font-size: 14px;
  color: #626262;
}


.group {
  background: #FAFAFA !important;
  border: 1px solid #c0c0c0 !important;
}

.rule {
  background-color: white !important;
  border: 1px solid #d3d3d3 !important;
  padding: 10px;
}

.ant-btn-primary {
  color: #fff;
  border-color: #FD9567 !important;
  background: #FD9567 !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn:hover, .ant-btn:focus {
  color: #FD9567 !important;
  border-color: #FD9567 !important;
  background: #fff !important;
}

.demo-editor{
  height: 400px !important;
  border: 1px solid #f1f1f1;
}

.rdw-editor-main {
  padding-right: 10px
}

.orangeText {
  color: #F57C23 !important;
}

.size28 {
  font-size: 28px !important;
}

.size31 {
  font-size: 31px !important;
}

.size-16 {
  font-size: 16px !important;

}

.size-14 {
  font-size: 14px !important;

}

.size25{
  font-size: 25px !important;
}

.size22{
  font-size: 22px !important;
}

.size31{
  font-size: 31px !important;
}

.size20{
  font-size: 20px !important;
}

.grayText {
  color: #939DA9 !important;
}

.padding40 {
  padding: 40px !important;
}

.padding20 {
  padding: 20px !important;

}
#jsonEditorId-4503 span[type ~= "error"]{
  color: red;
}

.fontWeight600 {
  font-weight: 600 !important;
}
.greenText {
  color: #04A400 !important;
}

.fontWeight500 {
  font-weight: 500 !important;
}

.textAlignCenter {
  text-align: center !important;
}

.purpleText {
  color: #6A64D2 !important;
}

.whiteText {
  color: white !important;
}


/* svg classes for hompepage connectors */
.path1 {
  stroke-dasharray: 1000;
  stroke-dashoffset: -500;
  animation-name: dash;
  animation-duration: 1.3s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.path2 {
  stroke-dasharray: 1000;
  stroke-dashoffset: -500;
  animation-name: dash;
  animation-duration: 1.3s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.path3 {
  stroke-dasharray: 1000;
  stroke-dashoffset: -500;
  animation-name: dash;
  animation-duration: 1.3s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

@keyframes dash {
  100% {
    stroke-dashoffset: 0;
  }
}

.pinktext {
  color: #D26478;
}

.ant-select-dropdown {
  z-index: 2000;
}

.size-18{
  font-size: 18px !important;
}

.wordBreak {
  word-break: break-word !important;
}

.secondaryColorText {
  color: #152945 !important;
}

.breakWord {
  word-break: break-all;
}

/* Divider styling */
.resizer {
  width: 5px;
  background: #e0e0e0;
  cursor: col-resize;
}

.resizer:hover {
  background: #ccc;
}

.ag-watermark {
  display: none;
}